// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donde-estamos-js": () => import("./../../../src/pages/donde-estamos.js" /* webpackChunkName: "component---src-pages-donde-estamos-js" */),
  "component---src-pages-equipo-humano-js": () => import("./../../../src/pages/equipo-humano.js" /* webpackChunkName: "component---src-pages-equipo-humano-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-normativa-meco-js": () => import("./../../../src/pages/normativa-meco.js" /* webpackChunkName: "component---src-pages-normativa-meco-js" */),
  "component---src-pages-seguros-de-salud-js": () => import("./../../../src/pages/seguros-de-salud.js" /* webpackChunkName: "component---src-pages-seguros-de-salud-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-medical-service-js": () => import("./../../../src/templates/medical-service.js" /* webpackChunkName: "component---src-templates-medical-service-js" */),
  "component---src-templates-season-js": () => import("./../../../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */)
}

